import React from "react"
import { Link, graphql } from "gatsby"
import { LatLon } from "geodesy/osgridref.js"
import Img from "gatsby-image"
import { VictoryArea, VictoryChart, VictoryAxis } from "victory"
import { Marker, GeoJSON } from "react-leaflet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Map from "../components/Map"
import ShareButtons from "../components/socialshare"

import "./walk.css"

const WalkTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const { markdownRemark: post } = data // data.markdownRemark holds your post data
  const { childGpx: route } = post.frontmatter.gpx // data.gpxFileData holds the GPX information to plot
  const url = location.href ? location.href : ""

  const cumulativeDistance = route.gpx.tracks[0].distance.cumul
  const elevationData = route.gpx.tracks[0].points.map(value => value.ele)

  const wgs84 = new LatLon(
    route.geojson.routeStart[0],
    route.geojson.routeStart[1]
  )
  const gridref = wgs84.toOsGrid()
  console.log(gridref.toString())

  let coords = cumulativeDistance.map((v, i) => ({
    Distance: v,
    Elevation: elevationData[i],
  }))

  const seoImage = post.frontmatter.social
    ? post.frontmatter.social.childImageSharp.resize
    : null

  // take the first three tags and remove any spaces for twitter
  const twitterTags = post.frontmatter.keywords
    .slice(0, 3)
    .map(str => str.replace(/\s/g, ""))

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={seoImage}
        pathname={location.pathname}
        keywords={post.frontmatter.keywords}
      />

      <article
        className="walk-page"
        itemScope
        itemType="http://schema.org/Article"
      >
        <div class="hero mx-n3">
          {post.frontmatter.featured && (
            <Img
              fluid={post.frontmatter.featured.childImageSharp.fluid}
              alt={post.frontmatter.title}
            />
          )}
          <h1 itemProp="headline" class="hero-text-bottom-left">
            {post.frontmatter.title}
          </h1>
        </div>
        <div class="m-md-3">
          <div class="float-right my-1">
            <ShareButtons
              title={post.frontmatter.title}
              url={url}
              twitterHandle={data.site.siteMetadata.social.twitter}
              tags={twitterTags}
            />
          </div>
          <small class="flex-fill align-text-bottom">
            {post.frontmatter.author} - {post.frontmatter.date}
          </small>

          <Map bounds={route.geojson.routeBounds}>
            <Marker position={route.geojson.routeStart} />
            <Marker position={route.geojson.routeEnd} />
            <GeoJSON data={route.geojson._0} />
          </Map>

          <hr />
          {/* TODO calculate elevation from coords https://nationalmap.gov/epqs/ */}
          <div className="elevation-container">
            <VictoryChart
              height={180}
              width={1000}
              padding={{ top: 20, left: 50, right: 0, bottom: 30 }}
            >
              <VictoryAxis
                style={{
                  grid: { stroke: "#02b9f5", strokeWidth: 0.7 },
                }}
                // tickFormat specifies how ticks should be displayed
                tickFormat={x => `${x / 1000} km`}
              />
              <VictoryAxis
                style={{
                  grid: { stroke: "#02b9f5", strokeWidth: 0.7 },
                }}
                dependentAxis
                // tickFormat specifies how ticks should be displayed
                tickFormat={x => `${x}m`}
              />
              <VictoryArea
                style={{
                  data: {
                    fill: "#f5f4f4",
                    fillOpacity: 0.7,
                    stroke: "#f79d7a",
                    strokeWidth: 2,
                  },
                }}
                data={coords} // data accessor for x values
                x="Distance"
                // data accessor for y values
                y="Elevation"
              />
            </VictoryChart>
          </div>
          <div id="Statistics" class="table-responsive">
            <table class="table">
              <caption class="text-center">Walk Information</caption>
              <tbody>
                <tr>
                  <td>
                    <strong>OS Grid Ref: </strong>
                    {gridref.toString()}
                  </td>
                  <td>
                    <strong>Distance: </strong>
                    {parseFloat(
                      route.gpx.tracks[0].distance.total / 1000
                    ).toFixed(1)}{" "}
                    km
                  </td>
                  <td>
                    <strong>Ascent: </strong>
                    {parseFloat(route.gpx.tracks[0].elevation.pos).toFixed(0)}m
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Duration: </strong>
                    {Math.floor(route.geojson.durationms / 3600000)}-
                    {Math.ceil(route.geojson.durationms / 3600000)} hours
                  </td>
                  <td>
                    <strong>Difficulty: </strong>
                    {post.frontmatter.difficulty}
                  </td>
                  <td>
                    <a href={post.frontmatter.gpx.publicURL} download>
                      Download the GPX File
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <strong>Parking: </strong>
                    {post.frontmatter.parking}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </div>
      </article>
      <nav className="walk-page-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default WalkTemplate

export const pageQuery = graphql`
  query WalkBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
        social {
          twitter
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        description
        difficulty
        parking
        keywords
        gpx {
          publicURL
          childGpx {
            geojson {
              routeBounds
              routeStart
              routeEnd
              durationms
              _0 {
                type
                geometry {
                  type
                  coordinates
                }
              }
            }
            gpx {
              tracks {
                distance {
                  cumul
                  total
                }
                elevation {
                  pos
                }
                points {
                  ele
                  time
                }
              }
            }
          }
        }
        social {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
        featured {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }

    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
